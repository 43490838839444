exports.songsdata = [
    {
        "title": "Drake - Forever",
        "url": "https://6a63fca904fd268f15f7-d5770ffdd579eb31eaa89faeffc55fe7.ssl.cf1.rackcdn.com/LE_listening_A2_Morning_briefing.mp3"
    },
    {
        "title": "Linking Park - In the end",
        "url": "https://beardbarnmusicbucket.s3.amazonaws.com/The+Wild+Horse"
    },
    {
        "title": "Travis Scott - Stop trina be God",
        "url": "https://beardbarnmusicbucket.s3.amazonaws.com/The+Wild+Horse"
    }
]