export const parseSafe = (str) => {
    try {
      const result = JSON.parse(str)
  
      return result
    } catch (e) {
      return undefined
    }
  }

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)

    reader.onload = () => resolve(reader.result)

    reader.onerror = (error) => reject(error)
  })